import { Directive } from "@angular/core";
import { NgControl } from "@angular/forms";
import { UrlFieldValidators } from "../url-field-validators";

@Directive({
  selector: '[httpValidator]',
  standalone: true
})
export class HttpValidatorDirective {
  constructor(control: NgControl) {
    control && control.control.addValidators(UrlFieldValidators.http);
  }
}