import { Directive, Input } from "@angular/core";
import { NgControl } from "@angular/forms";
import { StorageUtilsService } from "src/app/storage/services/storage-utils.service";
import { UrlFieldValidators } from "../url-field-validators";

@Directive({
  selector: '[urlFileTypeValidator]',
  standalone: true
})
export class UrlFileTypeValidatorDirective {
  @Input() set acceptedFileTypes(type: keyof typeof StorageUtilsService.SELECTOR_FILTERS) {
    this.control && this.control.control.addValidators(
      UrlFieldValidators.file(type)
    );
  }
  constructor(private control: NgControl) {
  }
}